<template>
  <section class="intro-x my-5">
    <div class="w-full flex my-5 justify-between">
      <div class="flex">
        <back-button />
        <h1 class="font-bold text-xl my-auto ml-2">Productos</h1>
      </div>
      <router-link
      v-if="$can('pharmasan.administrativa.compras-administrativas.ajustes.productos.access')"
      :to="{name: 'pharmasan.administrativa.compras-administrativas.ajustes.productos.carga-masiva'}"
      class="btn btn-primary capitalize flex items-center"
      >
        <span class="mr-2">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M14,13V17H10V13H7L12,8L17,13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
          </svg>
        </span>
        <span style="position:relative; top:1px;">Carga masiva </span>
      </router-link>
    </div>
    <div class="w-full flex my-2">
      <div class="w-1/2 flex ml-2">
        <label for="buscar" class="my-auto">Buscar artículo</label>
        <input
        type="text"
        name="buscar"
        class="form-control ml-2"
        v-model="filter.query"
        placeholder="Buscar por nombre de artículo">
      </div>
    </div>
    <!-- botones -->
    <div class="w-full flex items-center ">
      <button
      type="button"
      class="btn btn-primary ml-2"
      @click="onFilter"
      >
        Filtrar
      </button>

      <button
      type="button"
      class="btn btn-warning ml-2"
      @click="onFilterClear"
      v-if="$can('pharmasan.administrativa.compras-administrativas.ajustes.productos.access')"
      >
        Limpiar
      </button>
      <router-link
      v-if="$can('pharmasan.administrativa.compras-administrativas.ajustes.productos.access')"
      :to="{name: 'pharmasan.administrativa.compras-administrativas.inventario.form'}"
      class="btn btn-primary ml-2"
      >
        Agregar
      </router-link>
    </div>
    <div class="w-full">
      <DataTable @sort="orderFiled" :value="getProducts" class="p-datatable-sm overflow-x-auto">
        <Column header="Imagen" field="picture" headerClass="text-center" headerStyle="width: 70px;">
          <template #body="{ data }">
            <img :src="getImagePath(data)" :alt="data.picture">
          </template>
        </Column>
        <Column header="Cod. Producto" field="code" headerStyle="width: 110px;" sortable/>
        <Column header="Artículo" field="name" headerStyle="width:240px;" sortable />
        <!-- <Column
        header="Presentacion(es)"
        field="name"
        bodyClass="flex flex-wrap"
        headerStyle="width:190px;"
        headerClass="text-center"
        >
          <template #body="{ data }">
            <div class="text-center justify-center flex flex-wrap flex-1">
              <div
              v-for="(row,k) in data.presentations"
              :key="k"
              class="bg-gray-300 p-2 rounded-md mr-1 mb-1"
              >
                {{row.presentation.name}}
              </div>
            </div>
          </template>
        </Column> -->
        <Column header="Ex. Minima" field="minimum_stock" headerStyle="width: 120px;" sortable/>
        <Column header="Und. Medida" field="unit.name" headerStyle="width: 120px;" />
        <Column header="Categoria" field="category.name" headerStyle="width: 120px;" />
        <Column header="Sub Categoria" field="sub_category.name"  headerStyle="width: 120px;"/>
        <Column header="Habilitado" field="state" headerStyle="width: 120px;" headerClass="text-center" sortable>
          <template #body="{ data }">
          <div class="text-center">
            <span v-if="data.state">Si</span>
            <span v-else>No</span>
            </div>
          </template>
        </Column>
        <Column v-if="$can('pharmasan.administrativa.compras-administrativas.ajustes.productos.access')" header="Acciones" headerStyle="width:150px;" headerClass="text-center">
          <template #body="{data}">
            <div class="text-center">
            <router-link
            :to="{name:'pharmasan.administrativa.compras-administrativas.ajustes.productos.edit', params:{ id: data.id}}"
            class="btn btn-primary py-1 px-2"
            title="Editar"
            >
              <Edit2Icon class="w-4 h4 " />
            </router-link>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="w-full">
      <Paginator
      :rows="getPaginate.perPage"
      :totalRecords="getPaginate.total"
      :first="getPaginate.first"
      :rowsPerPageOptions="[10,20,30]"
      @page="onPage($event)"
      />
    </div>
  </section>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import articleList from '../../../../../services/listArticle'
import configShops from '../../../../../../../../config/shops'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Paginator from 'primevue/paginator'
import { computed, onMounted, reactive, watch, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'ProductosList',
  components: {
    DataTable,
    Column,
    Paginator
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const filter = ref({
      query: ''
    })

    const products = reactive({
      last_page: 0,
      current_page: 0,
      per_page: 0,
      total: 0,
      data: []
    })

    const getProducts = computed(() => {
      return products.data
    })
    const getPaginate = computed(() => {
      return {
        perPage: products.per_page,
        total: products.total,
        first: +route.query.first || 0
      }
    })
    const onPage = (row) => {
      const page = row.page + 1
      const paginate = row.rows
      const queryParams = { ...route.query }
      const first = row.first
      router.push({ query: { ...queryParams, page, first, paginate, ...filter.value } })
    }
    const getImagePath = ({ picture, name }) => {
      if (picture) return `${configShops.api}/files/${picture}`
      return `https://ui-avatars.com/api/?background=125386&color=fff&name=${name}`
    }

    const fetchArticles = () => {
      const queryParams = { ...route.query }
      queryParams.page = queryParams.page ? queryParams.page : 1
      queryParams.paginate = queryParams.paginate ? queryParams.paginate : 10
      return articleList(queryParams).then(({ status, data }) => {
        if (status !== 200) return false
        for (const [key, value] of Object.entries(data)) {
          products[key] = value
        }
      }).catch(err => {
        console.error(err.message)
      })
    }
    const onFilter = () => {
      const queryParams = route.query
      router.push({ query: { ...queryParams, ...filter.value } })
    }
    const filterFill = () => {
      const { query } = route.query
      filter.value.query = query || ''
    }
    const onFilterClear = () => {
      filter.value.query = ''
    }
    const orderFiled = ({ sortField, sortOrder }) => {
      const queryParams = route.query
      router.push({ query: { ...queryParams, order: `${sortField},${sortOrder}` } })
    }
    onMounted(() => {
      filterFill()
      fetchArticles()
    })
    watch(() => route.query, (value) => {
      fetchArticles()
    })
    return {
      filter,
      getProducts,
      getPaginate,
      onPage,
      onFilterClear,
      getImagePath,
      onFilter,
      orderFiled

    }
  }
}
</script>

<style lang="scss">
.text-center{
  .p-column-header-content{
    justify-content: center;
  }
}
</style>
